import React, { useEffect } from 'react'
import { Form, FormInstance } from 'antd'
import { get } from 'lodash-es'
export const FormContext = React.createContext({
  onFieldChange: (fieldName, value) => {},
  getFieldValue: (fieldName: string): any => {},
})

/**
 * A custom form component that provides a context for managing form fields.
 *
 * @component
 * @param {ReactNode} children - The content to be rendered inside the form.
 * @param {FormInstance | React.RefObject<FormInstance>} form - The form instance or a ref to the form instance.
 * @param {Function} onFieldChange - A callback function triggered when a form field value changes.
 * @param {object} initialValues - The initial values for the form fields.
 * @param {boolean} ready - Indicates whether the form is ready to be rendered.
 * @param {object} props - Additional props to be passed to the antd Form component.
 * @returns {JSX.Element} The rendered form component.
 */
export const AppForm = ({ children, form, onFieldChange = null, initialValues, ready = false, ...props }) => {
  let formRefDefault = Form.useForm()[0]
  let formRef: FormInstance = form?.current || formRefDefault
  const contextAPI = {
    onFieldChange: (fieldName, value) => {
      if (onFieldChange) onFieldChange(fieldName, value)
    },
    getFieldValue: fieldName => {
      return formRef?.getFieldValue?.(fieldName) ?? get(initialValues, fieldName)
    },
  }

  useEffect(() => {
    if (formRef && !form.current) {
      form.current = formRef
    }

    return () => {
      if (form.current) {
        formRef.des
      }
    }
  }, [form, formRef])

  if (!ready) {
    return <Form {...props}>{children}</Form>
  }
  return (
    <FormContext.Provider value={contextAPI}>
      <Form form={formRef} initialValues={initialValues} {...props}>
        {children}
      </Form>
    </FormContext.Provider>
  )
}
