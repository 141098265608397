import React from 'react'
import { observer } from 'mobx-react'
import AppModal from '../AppModal'
import { toJS } from 'mobx'
import RecentChangeForm from './RecentChangeForm'
import { ApiService } from '@services/ApiService'
import { Spin } from 'antd'
import { AppDateTimeInfo } from '@components/AppComponents'
import appStore from '@stores/appStore'

const renderValue = value => {
  if (!value || value === 'null') {
    return ''
  }
  const isHtmlContent = appStore.isHTML(value)
  value = value.replaceAll('"', '')
  return (
    <div>
      {isHtmlContent ? (
        <div
          style={{ maxWidth: '400px', wordBreak: 'break-word', whiteSpace: 'normal', overflowWrap: 'break-word' }}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      ) : (
        <p>{value.replaceAll('"', '').replaceAll(/\\n/g, '\n')}</p>
      )}
    </div>
  )
}

export const columnsWithReferenceTable = [
  {
    title: 'Old Value',
    accessor: 'originalValueExtraInfo',
    Cell: (_, record) => record.originalValueExtraInfo?.text,
  },
  {
    title: 'New Value',
    accessor: 'newValueExtraInfo',
    Cell: (_, record) => record.newValueExtraInfo?.text,
  },
  {
    title: 'Updated By',
    accessor: 'user',
    Cell: (_, record) => record.user?.displayName,
  },

  {
    title: 'Updated At',
    Cell: (_, record) => <AppDateTimeInfo date={record.executionTime} />,
  },
]

export const defaultColumns = [
  {
    title: 'Old Value',
    accessor: 'originalValue',
    Cell: (_, record) => renderValue(record.originalValue),
  },
  {
    title: 'New Value',
    accessor: 'newValue',
    Cell: (_, record) => renderValue(record.newValue),
  },
  {
    title: 'Updated By',
    accessor: 'user',
    Cell: (_, record) => record.user?.displayName,
  },

  {
    title: 'Updated At',
    accessor: 'changeTime',
    Cell: (_, record) => <AppDateTimeInfo date={record.changeTime} />,
  },
]

interface Props {
  entityId: string
  propertyReferenceTable?: string
  propertyName: string
  optionsValue?: any[]
  title?: string
  columns: Array<any>
  open: boolean
  onCancel?: () => void
  modalWidth?: number
}

class ModalRecentChange extends React.Component<Props, any> {
  state = {
    loading: false,
    data: { items: [], totalCount: 0 },
  }
  componentDidMount() {
    this.getRecentChange()
  }

  async getRecentChange() {
    try {
      this.setState({ loading: true })
      const { entityId, propertyName, propertyReferenceTable, optionsValue } = this.props
      if (!entityId) {
        return
      }
      const { data } = await ApiService.EntityHistory.getRecentChangeById(entityId, {
        propertyName,
        propertyReferenceTable,
        maxResultCount: 100,
      })

      if (data.items.length > 0) {
        if (optionsValue) {
          data.items.forEach(item => {
            if (item.originalValue) {
              item.originalValue =
                optionsValue.find(x =>
                  typeof x.id === 'number'
                    ? x.id === Number(item.originalValue.replace(/"/g, ''))
                    : x.id === item.originalValue.replace(/"/g, ''),
                )?.displayName ?? item.originalValue
            }
            if (item.newValue) {
              item.newValue =
                optionsValue.find(x =>
                  typeof x.id === 'number'
                    ? x.id === Number(item.newValue.replace(/"/g, ''))
                    : x.id === item.newValue.replace(/"/g, ''),
                )?.displayName ?? item.newValue
            }
          })
        }

        this.setState({
          data: data,
        })
      }
    } catch (error) {
    } finally {
      this.setState({ loading: false })
    }
  }

  render() {
    const { open, columns, onCancel, title = 'View changes', modalWidth } = this.props
    const { data, loading } = this.state

    return (
      <AppModal open={open} width={modalWidth ?? 900} title={title} onCancel={onCancel}>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Spin />
          </div>
        ) : (
          <RecentChangeForm dataRecentChange={data.items} columns={toJS(columns)} />
        )}
      </AppModal>
    )
  }
}

export default observer(ModalRecentChange)
