import { GetBaseServiceFunctions, ApiRequest } from '../baseService'

const BaseRoute = 'app/marketing-campaigns'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)

function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}

function SendEmail(id, isPreview) {
  return ApiRequest.get(`${BaseRoute}/${id}/SendEmail?isPreview=${isPreview}`)
}

function GetUnsubscribeGroups() {
  return ApiRequest.get(`${BaseRoute}/GetUnsubscribeGroups`)
}

function GetUnsubscribedEmails(groupId?: number) {
  return ApiRequest.get(`${BaseRoute}/GetUnsubscribedEmails?groupId=${groupId}`)
}

function GetEmailsSentHistory(campaignId: string) {
  return ApiRequest.get(`${BaseRoute}/${campaignId}/GetEmailHistories`)
}

function GetSenders() {
  return ApiRequest.get(`${BaseRoute}/senders`)
}

function GetSingleSendSummary(campaignId: string) {
  return ApiRequest.get(`${BaseRoute}/${campaignId}/singleSendStatistic`)
}

function Duplicate(campaignId: string) {
  return ApiRequest.post(`${BaseRoute}/${campaignId}/duplicate`)
}

function SyncStatistics(campaignId: string) {
  return ApiRequest.post(`${BaseRoute}/${campaignId}/sync-statistics`)
}

const API = {
  ...baseFunctions,
  ExportSearchResult,
  SendEmail,
  GetUnsubscribeGroups,
  GetUnsubscribedEmails,
  GetEmailsSentHistory,
  GetSenders,
  GetSingleSendSummary,
  Duplicate,
  SyncStatistics,
}

export default API
