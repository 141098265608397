import React, { useState, useEffect } from 'react'
import { Spin } from 'antd'

interface LoadingSpinnerProps {
  spinning: boolean
  size?: 'small' | 'default' | 'large'
  delay?: number
  children?: React.ReactNode
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ spinning, size = 'default', delay = 5000, children }) => {
  const [showMessage, setShowMessage] = useState(false)

  useEffect(() => {
    if (spinning) {
      setShowMessage(false) // Reset message when spinner starts
      const timer = setTimeout(() => setShowMessage(true), delay)
      return () => clearTimeout(timer) // Cleanup timer
    }
  }, [spinning, delay]) // Runs when `spinning` or `delay` changes

  return (
    <div className="relative">
      <Spin spinning={spinning} size={size}>
        {children}
      </Spin>

      {spinning && showMessage && (
        <p className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center z-50 p-6 bg-white shadow-lg rounded">
          Hang tight! It's currently loading.
        </p>
      )}
    </div>
  )
}

export default LoadingSpinner
