import DyteVideoMeeting from '@components/VideoMeeting/DyteVideoMeeting'
import { getURLParams } from '@utils/functions'
import { Spin } from 'antd'
import { observer } from 'mobx-react'
import { useEffect, useState } from 'react'
import store from './store'
import LoadingSpinner from '@components/LoadingSpinner'

const AppVideoMeeting = () => {
  const [videoToken, setVideoToken] = useState<string>(null)
  const [kawaconnToken, setKawaconnToken] = useState<string>(null)

  useEffect(() => {
    const { token, redirectFromMAG } = getURLParams()

    setKawaconnToken(token)

    store.getVideoSession(token, redirectFromMAG).then(data => {
      setVideoToken(data.video_JWT ?? '')
    })
  }, [])

  const { loading, dataSession } = store

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <LoadingSpinner spinning={loading && !dataSession} />

      {videoToken && <DyteVideoMeeting token={videoToken} kawaconnVideoToken={kawaconnToken} />}
    </div>
  )
}

export default observer(AppVideoMeeting)
