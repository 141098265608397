import { FROM_MODULE_TYPE, IME_SERVICE_TYPE, MN_SERVICE_TYPE, SUPP_SERVICE_TYPE } from '@utils/constants'
import { ApiRequest } from '../baseService'

const BaseRoute = 'app/case-status-logs'

function CreateStatusLog(data) {
  return ApiRequest.post(`${BaseRoute}`, data)
}

function GetAll(fromModule, objectId, getLatest = false, getPrepayment = false) {
  return ApiRequest.get(`${BaseRoute}`, {
    params: { caseStatusFrom: fromModule, objectId: objectId, getLatest, getPrepayment },
  })
}

function CountCaseStatus(
  fromModule: FROM_MODULE_TYPE,
  serviceType: SUPP_SERVICE_TYPE = null,
  assessmentServiceType: IME_SERVICE_TYPE = null,
  isGetTelehealth = false,
) {
  return ApiRequest.get(`${BaseRoute}/countCaseStatus`, {
    params: { fromModule: fromModule, serviceType, assessmentServiceType, isGetTelehealth },
  })
}

function CountOverdueCaseStatus(fromModule: FROM_MODULE_TYPE, serviceType: any = '', assessmentServiceType = '') {
  return ApiRequest.get(`${BaseRoute}/countOverdueCaseStatus`, {
    params: { fromModule: fromModule, serviceType, assessmentServiceType },
  })
}

function ChangeCaseStatus(data) {
  return ApiRequest.post(`${BaseRoute}/changecasestatus`, data)
}

const API = {
  CreateStatusLog,
  GetAll,
  CountOverdueCaseStatus,
  CountCaseStatus,
  ChangeCaseStatus,
}

export default API
