import { ApiRequest, GetBaseServiceFunctions, queryClient } from '../baseService'

const BaseRoute = 'app/disbursements/repayment-invoice'

const baseFunctions = GetBaseServiceFunctions(BaseRoute)
function ExportSearchResult(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/export`, payload, { responseType: 'arraybuffer' })
}
function ExportStatementInvoice(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/exportStatementInvoice`, payload, { responseType: 'arraybuffer' })
}
function ExportOutstandingStatement(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/exportOutstandingStatement`, payload, { responseType: 'arraybuffer' })
}
function DaySheetPrint(id) {
  return ApiRequest.post(`${BaseRoute}/${id}/invoice-print`)
}
function OutStandingStatementPrint(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/outstanding-statement-print`, payload)
}

function GetFundingStatementData(params: any = {}) {
  if (params && !params.maxResultCount) {
    params.maxResultCount = window.APP_CONFIG.maxResultCount
  }
  return ApiRequest.get(`${BaseRoute}/OutstandingStatementData`, {
    params: params,
    paramsSerializer: {
      indexes: true,
    },
  })
}
function GetStatementInvoiceData(params: any = {}) {
  if (params && !params.maxResultCount) {
    params.maxResultCount = window.APP_CONFIG.maxResultCount
  }
  return ApiRequest.get(`${BaseRoute}/StatementInvoiceData`, {
    params: params,
    paramsSerializer: {
      indexes: true,
    },
  })
}
function GetAggregateOutstandingData(params: any = {}) {
  if (params && !params.maxResultCount) {
    params.maxResultCount = window.APP_CONFIG.maxResultCount
  }
  return ApiRequest.get(`${BaseRoute}/AggregateOutstandingData`, {
    params: params,
    paramsSerializer: {
      indexes: true,
    },
  })
}

function SendSelectedInvoices(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/SendSelectedInvoices`, payload)
}
function SendFundingStatement(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/SendFundingStatement`, payload)
}
function SendRemittanceAdvice(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/SendRemittanceAdvice`, payload)
}
function CreateRepaymentInvoiceFromOldDisbursementFunding(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/CreateRepaymentInvoiceFromOldDisbursementFunding`, payload)
}
function UpdateRepaymentInvoiceFromOldDisbursementFunding(payload = {}) {
  return ApiRequest.post(`${BaseRoute}/UpdateRepaymentInvoiceFromOldDisbursementFunding`, payload)
}
function UpdateInvoiceStatus(payload = {}) {
  return ApiRequest.put(`${BaseRoute}/UpdateInvoiceStatus`, payload)
}
const SendEmail = (id, data, isSMS = false) => {
  return ApiRequest.post(`${BaseRoute}/${id}/emails?emailType=${data}&isSMS=${isSMS}`)
}
const UpdateCaseDetail = data => {
  queryClient.invalidateQueries([BaseRoute, data.id])
  return ApiRequest.put(`${BaseRoute}/${data.id}/CaseDetail`, data)
}
const UpdateFundingType = data => {
  queryClient.invalidateQueries([BaseRoute, data.id])
  return ApiRequest.put(`${BaseRoute}/${data.id}/FundingType`, data)
}
const UploadToAccounting = id => {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.post(`${BaseRoute}/${id}/UploadToAccounting`)
}
const Cancelled = data => {
  queryClient.invalidateQueries([BaseRoute, data.id])
  return ApiRequest.put(`${BaseRoute}/${data.id}/Cancelled`, data, {
    params: {
      id: data.id,
      cancelledReason: data.cancelledReason,
    },
  })
}
function DownloadTemplateUpload() {
  return ApiRequest.post(`${BaseRoute}/template-import`, {}, { responseType: 'arraybuffer' })
}
function UploadFiles(file) {
  return ApiRequest.post(`${BaseRoute}/import`, file)
}
function CountOutstandingAmount(params: any = {}) {
  return ApiRequest.get(`${BaseRoute}/CountOutstandingAmount`, {
    params: params,
    paramsSerializer: {
      indexes: true,
    },
  })
}
function CountDisbursementStatus(params: any = {}) {
  return ApiRequest.get(`${BaseRoute}/CountDisbursementStatus`, {
    params: params,
    paramsSerializer: {
      indexes: true,
    },
  })
}
function CountOverdueForAgreement(params: any = {}) {
  return ApiRequest.get(`${BaseRoute}/CountOverdueForAgreement`, {
    params: params,
    paramsSerializer: {
      indexes: true,
    },
  })
}

const RemoveTransactionPayments = id => {
  queryClient.invalidateQueries([BaseRoute, id])
  return ApiRequest.delete(`${BaseRoute}/transactionPayments/${id}`)
}
const ExportSelectedInvoices = params => {
  return ApiRequest.post(`${BaseRoute}/ExportSelectedInvoices`, params, { responseType: 'arraybuffer' })
}
const API = {
  ...baseFunctions,
  ExportSearchResult,
  ExportStatementInvoice,
  ExportOutstandingStatement,
  OutStandingStatementPrint,
  GetFundingStatementData,
  SendSelectedInvoices,
  SendFundingStatement,
  SendRemittanceAdvice,
  GetStatementInvoiceData,
  GetAggregateOutstandingData,
  SendEmail,
  UpdateCaseDetail,
  UpdateFundingType,
  DaySheetPrint,
  CreateRepaymentInvoiceFromOldDisbursementFunding,
  UpdateRepaymentInvoiceFromOldDisbursementFunding,
  Cancelled,
  DownloadTemplateUpload,
  UploadFiles,
  CountOutstandingAmount,
  CountDisbursementStatus,
  CountOverdueForAgreement,
  RemoveTransactionPayments,
  ExportSelectedInvoices,
  UpdateInvoiceStatus,
  UploadToAccounting,
}

export default API
