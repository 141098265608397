import { Spin, Upload, message } from 'antd'
import { useEffect, useState } from 'react'
import { CloudUploadOutlined, DownloadOutlined } from '@ant-design/icons'
import { ApiService } from '@services/ApiService'
import { DEFAULT_FILE_TYPE, UPLOAD_IMAGE_FILE_TYPE } from '@utils/constants'
import { getFileExtension, getStaticFileUrl } from '@utils/functions'
import './styles.scss'
import appStore from '@stores/appStore'

const { Dragger } = Upload

function AppFileStaticUpload({
  title = 'Click or drag file to upload',
  name = 'file',
  type = DEFAULT_FILE_TYPE,
  descriptions = '',
  multiUpload = false,
  itemRender = false,
  maxSize = 100,
  previewTemplate = null,
  fileList = [],
  disabled = false,
  allType = null,
  onChange = (listFiles, file) => {},
  beforeUpload = file => {},
  replaceFile = '',
}) {
  const [_fileList, setFileList] = useState([])
  const [imgPreview, setImgPreview] = useState<any>('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (fileList.length) {
      setFileList(fileList.map(file => ({ ...file, status: 'done' })))
      fileList.forEach(file => {
        if (file.thumbUrl) {
          setImgPreview(file.thumbUrl)
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const _onChange = info => {
    const { status, uid } = info.file
    if (status === 'removed') {
      const fL = [..._fileList]
      const index = fL.findIndex(f => f.uid === uid)
      fL.splice(index, 1)
      setFileList([...fL])
      onChange([...fL], info.file)
      if (!multiUpload) {
        setImgPreview('')
      }
    }
  }

  const _beforeUpload = async file => {
    beforeUpload(file)
    let fileType = getFileExtension(file.name)
    const match = allType ? true : type.indexOf(fileType) !== -1
    if (match) {
      setImgPreview('')
      setLoading(true)
      let fL = [..._fileList]
      if (multiUpload) {
        if (fL.length >= maxSize) {
          message.warning(`you can only upload ${maxSize} files`)
          return Upload.LIST_IGNORE
        } else {
          fL = [..._fileList, file]
        }
      } else {
        fL = [file]
        if (type.indexOf(file.type) !== -1) {
          const img = await toBase64(file)
          setImgPreview(img)
        }
      }
      _upload(file, fL)
    } else {
      message.error(`Only '${type.join(', ')}' file accepted`)
    }
    return Upload.LIST_IGNORE
  }

  const _upload = (file, fL) => {
    const formData = new FormData()
    formData.append('name', file.name)
    formData.append('file', file)
    formData.append('type', file.type)
    const listFiles = [...fL]
    ApiService.FileStatic.Upload(formData, replaceFile)
      .then(({ data }) => {
        // message.success(`${file.name} file uploaded successfully.`)

        const fileData = {
          ...listFiles[listFiles.length - 1],
          status: 'done',
          name: data,
          thumbUrl: getStaticFileUrl(data),
          url: getStaticFileUrl(data),
        }
        listFiles[listFiles.length - 1] = fileData

        setImgPreview(fileData.thumbUrl)
        onChange(listFiles, listFiles[listFiles.length - 1])
      })
      .catch(err => {
        message.error(`${file.name} file upload failed.`)
        listFiles[listFiles.length - 1].status = 'error'
      })
      .finally(() => {
        setLoading(false)
        setFileList(listFiles)
      })
  }

  const toBase64 = file =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = error => reject(error)
    })

  return (
    <Dragger
      className="fileupload"
      onChange={_onChange}
      beforeUpload={_beforeUpload}
      name={name}
      onDownload={file => ApiService.FileStatic.Download(file)}
      itemRender={() => itemRender}
      fileList={_fileList}
      disabled={disabled || appStore.isDemoAccount}
      showUploadList={{
        showDownloadIcon: true,
        downloadIcon: () => <DownloadOutlined className="text-blue-400 inline" width="14" />,
        showRemoveIcon: true,
      }}
    >
      <div className="flex px-6">
        <div className="flex justify-center mr-6">
          {loading ? (
            <Spin />
          ) : (
            <>
              {(multiUpload || !imgPreview) && (
                <CloudUploadOutlined style={{ fontSize: 50, color: 'var(--blue-400)' }} />
              )}
              {!multiUpload &&
                imgPreview &&
                (previewTemplate ? (
                  previewTemplate
                ) : (
                  <div className="fileupload__preview">
                    <img src={imgPreview} alt="preview" />
                  </div>
                ))}
            </>
          )}
        </div>
        <div className="flex justify-center flex-col">
          <p className="text-left ant-upload-text text-blue-400">
            {!multiUpload && imgPreview ? 'Click or drag file to replace' : title}
          </p>
          <p className="text-left ant-upload-hint">{descriptions}</p>
        </div>
      </div>
    </Dragger>
  )
}

export default AppFileStaticUpload
