import { ApiRequest } from '../baseService'

const BaseRoute = 'app/leap-app'

const GetMatterDetail = (id, tenantId, companyPMSId = null) => {
  return ApiRequest.get(`${BaseRoute}/matters/${id}?tenantId=${tenantId ?? ''}&companyPMSId=${companyPMSId ?? ''}`)
}

const GetMatters = (companyPMSId = null) => {
  return ApiRequest.post(`${BaseRoute}/matters`, {
    method: 'Get',
    companyPMSId,
  })
}

const GetPersons = (filter, companyPMSId = null) => {
  return ApiRequest.post(`${BaseRoute}/persons`, {
    method: 'Get',
    data: filter?.query,
    companyPMSId,
  })
}

const GetMatterAccount = (responsibleUserId, clientRecordId, companyPMSId = null) => {
  return ApiRequest.get(
    `${BaseRoute}/mattersAccount?responsibleUserId=${responsibleUserId ?? ''}&clientRecordId=${
      clientRecordId ?? ''
    }&companyPMSId=${companyPMSId ?? ''}`,
  )
}

const API = {
  GetMatterDetail,
  GetMatters,
  GetMatterAccount,
  GetPersons,
}
export default API
