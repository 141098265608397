import { Input } from 'antd'
import { observer } from 'mobx-react'
import InputMask from 'react-input-mask'

const PhoneInput = ({ onChange, disabled, ...props }: { [key: string]: any }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const formattedValue = value.trim().replaceAll(' ', '')
    onChange(formattedValue)
  }
  return (
    <InputMask
      mask={'99 9999 9999'}
      value={props.value}
      onChange={handleChange}
      maskChar={' '}
      disabled={disabled}
      {...props}
      formatChars={{ '9': '[0-9]' }}
      beforeMaskedValueChange={(newState, oldState, userInput) => {
        if (!oldState.selection) {
          return newState
        }
        if (userInput === null && oldState.value[oldState.selection.start - 1] === ' ') {
          const newValue =
            oldState.value.slice(0, oldState.selection.start - 1) + oldState.value.slice(oldState.selection.start)
          return {
            value: newValue,
            selection: { start: oldState.selection.start - 1, end: oldState.selection.start - 1 },
          }
        }
        return newState
      }}
    >
      {inputProps => <Input disabled={disabled} {...inputProps} />}
    </InputMask>
  )
}

export default observer(PhoneInput)
