import { ApiRequest } from '../baseService'

const UpdateFromIME = (params, id) => {
  return ApiRequest.put(`/app/assessments/${id}/cancellation`, params)
}
const UpdateAttendanceFromIME = (params, id) => {
  return ApiRequest.put(`/app/assessments/${id}/attendance`, params)
}
const UpdateFromSupp = (params, id) => {
  return ApiRequest.put(`/app/supplementary/${id}/cancellation`, params)
}

const UpdateFromMR = (params, id) => {
  return ApiRequest.put(`/app/clinical-records/${id}/cancellation`, params)
}
const UpdateOnHoldFromMR = (params, id) => {
  return ApiRequest.put(`/app/clinical-records/${id}/OnHoldRequest`, params)
}

const UpdateFromTPD = (params, id) => {
  return ApiRequest.put(`/app/tpd/${id}/cancellation`, params)
}

const API = {
  UpdateFromIME,
  UpdateFromSupp,
  UpdateFromMR,
  UpdateAttendanceFromIME,
  UpdateOnHoldFromMR,
  UpdateFromTPD,
}
export default API
